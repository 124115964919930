import _ from 'lodash'
import { gql } from '~/apollo/gql'
import { defineStore } from 'pinia'
import { useFragment } from '~/apollo/fragment-masking'
import { OpenFeature } from '@openfeature/web-sdk'

export const useFestivalManager = defineStore('festivalManager', () => {
  const session = useSession()
  const openFeature = useOpenFeature()

  const loaded = ref(false)

  const { result, refetch, onResult, loading } = useQuery(FestivalManagerCache, () => ({
    userId: session.user?.id,
    userIdInt: parseInt(session.user?.id),
    ffid: openFeature.getNumber('current_film_festival'),
    ffidNum: openFeature.getNumber('current_film_festival')
  }), () => ({
    enabled: !!session.user
  }))

  onResult(({ loading }) => {
    if (!loading) {
      loaded.value = true
      OpenFeature.setContext({
        ...OpenFeature.getContext(),
        filmmaker: teams.value.length > 0
      })
    }
  })

  const teamRoles = computed(() => result.value?.team_roles?.map(r => useFragment(TeamRole, r)))
  const countries = computed(() => result.value?.countries?.map(c => useFragment(Country, c)))
  const genres = computed(() => result.value?.genres?.map(g => useFragment(Genre, g)))
  const festivalGenres = computed(() => result.value?.festival_genres?.map(g => useFragment(FestivalGenre, g)))
  const studios = computed(() => result.value?.studios?.map(s => useFragment(StudioInfoLite, s)))
  const teams = computed(() => _.chain(studios.value).flatMap(s => s.teams).filter(t => !!t.team_number).value() || [])
  const assetTypes = computed(() => result.value?.assetTypes?.map(a => useFragment(AssetType, a)))

  return { refetch, loading, loaded, teamRoles, countries, genres, festivalGenres, studios, teams, assetTypes }
})
const TeamRole = gql(`fragment TeamRole on team_roles {
  id name description
}`)

const Country = gql(`fragment Country on countries {
  id name code
}`)

const StudioInfoLite = gql(`fragment StudioInfoLite on studios {
  id name owner_id teams(where: {film_festival_id: {_eq: $ffidNum}}) { id name team_number submitted_at }
}`)

const Genre = gql(`fragment Genre on genres {
  id name description
}`)

const FestivalGenre = gql(`fragment FestivalGenre on festival_genres {
  id name description
}`)

const AssetType = gql(`fragment AssetType on asset_types {
  id title
}`)

const FestivalManagerCache =
  gql(`query FestivalManagerCache($userId: numeric!, $userIdInt: Int!, $ffid: Int!, $ffidNum: numeric!) {
  team_roles(where: { name: { _is_null: false }}, order_by: {name: asc}) {
    ...TeamRole
  }
  countries(where: { name: { _is_null: false }}, order_by: {name: asc}) {
    ...Country
  }
  genres(order_by: {name: asc}) {
    ...Genre
  }
  festival_genres(where: { film_festival_id: { _eq: $ffid } }, order_by: {name: asc}) {
    ...FestivalGenre
  }
  studios(where: {_or: [
    {owner_id: {_eq: $userIdInt}},
    {teams: {owner_id: {_eq: $userId}}},
    {teams: {memberships: {user_id: {_eq: $userId}}}}
  ]}) {
    ...StudioInfoLite
  }
  assetTypes: asset_types(where: {_or: [{hidden: {_is_null: true}},{hidden: {_neq: "Y"}}]} order_by: {title: asc}) {
    ...AssetType
  }
}
`)
